import { usePiletApi } from "piral-core";
import { useHistory } from "react-router";
import * as React from 'react'
declare var Blazor: any;

export default function BlazorIntegrator() {
    const history = useHistory();
    const piletApi = usePiletApi();

    let bnav = (e: any) => {
        history.push(e);
    }

    React.useEffect(() => {
        piletApi.on('blazorNavigate', bnav);
        piletApi.on('navigate', bnav);
        window.addEventListener('loaded-blazor-core', afterStarted, { once: true });
        return () => {
            removeEventListener('loaded-blazor-core', afterStarted);
            piletApi.off('blazorNavigate', bnav);
            piletApi.off('navigate', bnav);
        }
    }, [])
    return null;
}

class CustomEventMeta {
    constructor(public domEvent: String, public blazorEvent: String, public detailsGetter?: Function) { }
}

// Custom events that need to be listened to
const customEvents: Array<CustomEventMeta> = [
    new CustomEventMeta('confirmed-value-changed', 'confirmed-value-changed', (e: any) => e.detail?.value),
    new CustomEventMeta('change', 'sp-change', (e: any) => e.target?.checked),
    new CustomEventMeta('cancel', 'sp-cancel'),
    new CustomEventMeta('close', 'sp-close'),
    new CustomEventMeta('confirm', 'sp-confirm'),
    new CustomEventMeta('secondary', 'sp-secondary'),
    new CustomEventMeta('sp-closed', 'sp-closed'),
    new CustomEventMeta('sp-opened', 'sp-opened')
];

// A wrapper for CustomEvent class that reroutes bubbled events if in the customEvents list
CustomEvent = class Bubbled extends CustomEvent<any> {
    constructor(type: string, eventInitDict?: CustomEventInit<any> | undefined) {
        if (eventInitDict?.bubbles === true) {
            super(type, eventInitDict);
            return;
        }
        super(type, {
            ...eventInitDict,
            bubbles: !!customEvents.find(a => a.domEvent === type) ? true : eventInitDict?.bubbles
        });
    }
}

function defaultDetailGetter(e: any) { return e.detail; }

export function afterStarted() {
    if (Blazor.t_init) return;
    console.info('Talisman.Blazor bootstrapping events');
    // Register all the customEvents with Blazor
    customEvents.forEach(meta => {
        Blazor.registerCustomEventType(meta.blazorEvent, {
            browserEventName: meta.domEvent,
            createEventArgs: (e: CustomEvent) => {
                return {
                    detail: (meta.detailsGetter ?? defaultDetailGetter)(e)
                };
            }
        })
    });
    Blazor.t_init = true;
}