import * as React from 'react';
import { Link } from 'react-router-dom';
import { ComponentsState, ErrorComponentsState } from 'piral';
import DashboardContainer from './parts/DashboardContainer';
import DashboardTile from './parts/DashboardTile';
import Layout from './parts/Layout';
import MenuContainer from './parts/MenuContainer';
import MenuItem from './parts/MenuItem';
import NotificationsHost from './parts/NotificationsHost';
import NotificationsToast from './parts/NotificationsToast';
import ErrorContainer from './parts/ErrorContainer';
import SearchContainer from './parts/SearchContainer';
import SearchInput from './parts/SearchInput';
import SearchResult from './parts/SearchResult';
import LoadingIndicator from './parts/LoadingIndicator';

export const errors: Partial<ErrorComponentsState> = {
  not_found: e => (
    <div>
      <p>This page could not be found</p>
      <p>
        Head <Link to="/">home</Link>.
      </p>
    </div>
  ),
  extension: e => (
    <div>
      Extension error
    </div>
  ),
  loading: e => (<div>{e.type}:{e.error.toString()}</div>),
  page: e => (<div>Page error</div>),
  unknown: e => (<div>Unknown error</div>)
};

export const structure: Partial<ComponentsState> = {
  ErrorInfo: ErrorContainer,
  LoadingIndicator: LoadingIndicator,
  DashboardContainer: DashboardContainer,
  DashboardTile: DashboardTile,
  Layout: Layout,
  MenuContainer: MenuContainer,
  MenuItem: MenuItem,
  NotificationsHost: NotificationsHost,
  NotificationsToast: NotificationsToast,
  SearchContainer: SearchContainer,
  SearchInput: SearchInput,
  SearchResult: SearchResult
};
