import * as React from 'react'

export default function AboutMenu() {
    return (
        <sp-menu-item href="/about">
            <sp-icon-info slot="icon"></sp-icon-info>
            About
        </sp-menu-item>
    )
}
