import { BaseComponentProps } from 'piral-core';
import * as React from 'react'

export default function ServiceWorkerRegistration(props: BaseComponentProps) {
    // Register a Service Worker.
    navigator.serviceWorker.register('/serviceworker.js');
    navigator.serviceWorker.ready.then(registration => {
        if (!('serviceWorker' in navigator)) {
            // Service Worker isn't supported on this browser
            return;
        }

        if (!('PushManager' in window)) {
            // Push isn't supported on this browser
            return;
        }

        if (Notification.permission === 'granted') return;

        console.log('Requesting notification permission');
        Notification.requestPermission();
    });

    return null;

}