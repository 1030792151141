import { ExtensionSlot, LayoutProps } from 'piral-core'
import * as React from 'react'
import './Layout.scss'
import { Tracker } from 'piral-tracker'

export const T_LAYOUT_COLOR_KEY = 't-l-color';
export const T_LAYOUT_SCALE_KEY = 't-l-scale';

export default function Layout(props: LayoutProps) {
    const [userColor, setUserColor] = React.useState(localStorage.getItem(T_LAYOUT_COLOR_KEY));
    const [userScale, setUserScale] = React.useState(localStorage.getItem(T_LAYOUT_SCALE_KEY));
    const [systemColor, setSystemColor] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

    React.useEffect(() => {
        dispatchEvent(new CustomEvent('talisman:loaded'));
    }, []);

    // When the local storage settings change, update
    React.useEffect(() => {
        let callback = () => {
            setUserScale(localStorage.getItem(T_LAYOUT_SCALE_KEY));
            setUserColor(localStorage.getItem(T_LAYOUT_COLOR_KEY));
        }
        window.addEventListener('storage', callback);
        return () => {
            window.removeEventListener('storage', callback);
        }
    }, [])

    // When the underlying browser requests a theme change
    React.useEffect(() => {
        let callback = (e: MediaQueryListEvent) => {
            setSystemColor(e.matches ? 'dark' : 'light');
        }
        let mediaWatch = window.matchMedia('(prefers-color-scheme: dark)');
        mediaWatch.addEventListener('change', callback);
        return () => {
            mediaWatch?.removeEventListener('change', callback);
        }
    }, [])

    return (
        <>
            <Tracker></Tracker>
            <sp-theme scale={userScale ?? 'medium'} color={userColor ?? systemColor}>
                <ExtensionSlot name='system-extension'></ExtensionSlot>
                {props.children}
            </sp-theme>
        </>
    )
}
