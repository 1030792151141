import { DashboardContainerProps, ExtensionSlot, usePiletApi } from 'piral'
import * as React from 'react'
import { Redirect } from 'react-router';

export default function DashboardContainer(props: DashboardContainerProps) {
    let appsettings = usePiletApi().getData('appsettings');
    if (appsettings?.homeModule) return <Redirect to={appsettings.homeModule}></Redirect>;
    return (
        <ExtensionSlot name='layout-dashboard-container' params={props}></ExtensionSlot>
    )
}
