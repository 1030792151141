import { PageComponentProps } from 'piral-core'
import * as React from 'react'
import './SignOutCallback.scss'

export default function SignOutCallback(props: PageComponentProps) {
    return (
        <div className='signoutcallback-container'>
            <sp-popover open dialog>
                <div flex-layout-column='' flex-gap-xl=''>
                    <sp-illustrated-message heading='Signed out' description='You have been signed out'>
                        <t-logo compact></t-logo>
                    </sp-illustrated-message>
                    <sp-button href="/">
                        Sign back in
                    </sp-button>
                </div>
            </sp-popover>
        </div>)
}
