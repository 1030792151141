import { SearchResultProps } from 'piral-search'
import * as React from 'react'

export default function SearchResult(props: SearchResultProps) {
    return (
        <>
            {props.children}
        </>
    )
}
