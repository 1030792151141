import { SearchContainerProps } from 'piral-search'
import * as React from 'react'

export default function SearchContainer(props: SearchContainerProps) {

    const [searchHistory, setSearchHistory] = React.useState([]);

    React.useEffect(() => {
        localStorage.setItem('t-search-history', JSON.stringify(searchHistory));
    }, [searchHistory])

    React.useEffect(() => {
        var history = localStorage.getItem('t-search-history');
        if (!history) return;
        setSearchHistory(JSON.parse(history));
    }, []);

    return (
        <div flex-layout-column=''>
            {/* <sp-progress-circle indeterminate='' size="s"></sp-progress-circle> */}
            {searchHistory?.length > 0 &&
                <sp-divider></sp-divider>
            }
            {Array.isArray(props.children) &&
                <>
                    <t-typography variant="Detail" size="S" emphasis="light">Help and documentation</t-typography>
                    {props.children}
                </>
            }
        </div>
    )
}
