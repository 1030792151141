import { PiralInstance } from "piral-core";
import BlazorIntegrator from "./blazor";
import SignOutCallback from "./pages/SignOutCallback";
import * as React from 'react'
import HomeMenu from "./components/HomeMenu";
import AboutMenu from "./components/AboutMenu";
import ServiceWorkerRegistration from "./ServiceWorkerRegistration";
import SettingsAccessibility from "./components/SettingsAccessibility";
import SearchMenu from "./components/SearchMenu";

export function startup(instance: PiralInstance) {
    // Get the appsettings
    let appsettings = instance.root.getData('appsettings');

    // Register global trackers
    instance.root.registerTracker(BlazorIntegrator);
    instance.root.registerTracker(ServiceWorkerRegistration);

    // Debug support
    const debugAuth = React.lazy(() => import('./pages/DebugAuth'));
    instance.root.registerPage('/devstart', debugAuth, { layout: 'page' });

    // Authentication and user profile
    const signOutPage = React.lazy(() => import('./pages/SignOut'));
    instance.root.registerPage('/signout', signOutPage, { layout: 'page' });
    instance.root.registerPage('/signoutcallback', SignOutCallback, { layout: 'page' });
    const userMenuItem = React.lazy(() => import('./components/UserMenu'));
    const profilePage = React.lazy(() => import('./pages/Profile'));
    const userProfileExtension = React.lazy(() => import('./components/UserProfile'));
    const accountInfoExtension = React.lazy(() => import('./pages/AccountInfo'));
    instance.root.registerMenu('system-user', userMenuItem, { type: 'header', priority: 8 });
    instance.root.registerPage('/profile', profilePage);
    instance.root.registerExtension('profile-extension', userProfileExtension, {
        label: 'My profile'
    });
    instance.root.registerExtension('profile-extension', accountInfoExtension, {
        label: 'Account information and access'
    });

    // Settings
    const settingsMenuItem = React.lazy(() => import('./components/SettingsMenu'));
    const settingsPage = React.lazy(() => import('./pages/Settings'));
    instance.root.registerPage('/settings/:id?', settingsPage);
    instance.root.registerMenu('system-settings', settingsMenuItem, { type: 'header', priority: 7 });
    instance.root.registerExtension('settings-extension', SettingsAccessibility, {
        label: 'Accessibility'
    });

    // Register system menus
    instance.root.registerMenu('system-home', HomeMenu);
    instance.root.registerExtension('settings-menu-extension', AboutMenu, { type: 'header' });
    let aboutPage = React.lazy(() => import('./pages/About'));
    instance.root.registerPage('/about', aboutPage);

    // Stuff that's in preview atm
    if (appsettings?.preview === true) {
        // Register search menu
        instance.root.registerMenu('system-search', SearchMenu, { type: 'header' });
    }
}