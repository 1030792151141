import { Search, SearchInput } from 'piral-search';
import React from 'react'

declare global {
    namespace JSX {
        interface IntrinsicAttributes {
            [key: string]: any;
        }
    }
}

export default function SearchMenu() {

    let onFocus = (e: any) => {
        debugger;
    }

    return (
        <>
            <sp-action-button quiet id="t-ml-searchmenu-trigger">
                <sp-icon-search slot="icon"></sp-icon-search>
            </sp-action-button>
            <sp-overlay placement="left" trigger="t-ml-searchmenu-trigger@click">
                <sp-popover>
                    <div padded-xs=''>
                        <SearchInput></SearchInput>
                    </div>
                    <Search></Search>
                </sp-popover>
            </sp-overlay>
        </>
    )
}
