import { MenuContainerProps } from 'piral'
import * as React from 'react'
import './MenuContainer.scss'
import { useHistory } from 'react-router'

export default function MenuContainer(props: MenuContainerProps) {
  let history = useHistory();

  // A default clickhandler for all menu items to redirect to the internal router
  const clickHandler = (e: MouseEvent) => {
    if (e?.target?.getAttribute('t-alter-hrefs-disable')) {
      // Don't handle these
      return;
    }
    if (e?.target?.role === 'menuitem') {
      e.preventDefault();
      e.target.dispatchEvent(
        new Event("close", {
          bubbles: true,
          composed: true
        })
      );
    }
    if (e?.target?.getAttribute('href')) {
      e.preventDefault();
      history.push(e.target.getAttribute('href'));
    }
  }

  // Retrieve the nodes by their priority order, where no priority items go first
  const orderByPriority = React.useCallback((nodes: React.ReactNode[]) => {
    var result = (nodes || [])
      .sort((a, b) => {
        let aPriority = a?.props?.meta?.priority;
        let bPriority = b?.props?.meta?.priority;
        if (aPriority === bPriority) return 0;
        if (!aPriority) return -1;
        if (!bPriority) return 1;
        return aPriority > bPriority ? 1 : -1;
      });

    return result;
  }, []);

  function HeaderMenuContainer(props: MenuContainerProps) {
    return (
      <sp-action-group quiet class={`${props.type} menu-container`} onClick={clickHandler}>
        {orderByPriority(props.children)}
      </sp-action-group>
    )
  }

  function GeneralMenuContainer(props: MenuContainerProps) {
    return (
      <sp-sidenav class={`${props.type} menu-container`} onClick={clickHandler}>
        {props.children}
      </sp-sidenav>
    )
  }

  function DefaultMenuContainer(props: MenuContainerProps) {
    return (
      <>
        {props.children}
      </>
    )
  }

  return (
    <>
      {props.type === 'header' && HeaderMenuContainer(props)}
      {props.type === 'general' && GeneralMenuContainer(props)}
      {props.type === 'user' && DefaultMenuContainer(props)}
      {props.type === 'admin' && DefaultMenuContainer(props)}
      {props.type === 'footer' && DefaultMenuContainer(props)}
    </>
  );
}