import { ExtensionComponentProps } from 'piral-core'
import * as React from 'react'
import { T_LAYOUT_COLOR_KEY, T_LAYOUT_SCALE_KEY } from '../parts/Layout';

export default function SettingsAccessibility(props: ExtensionComponentProps<any>) {
    const [scale, setScale] = React.useState(localStorage.getItem(T_LAYOUT_SCALE_KEY))
    const [color, setColor] = React.useState(localStorage.getItem(T_LAYOUT_COLOR_KEY))

    let colorSelector = React.createRef<HTMLElement>();
    let scaleSelector = React.createRef<HTMLElement>();

    React.useEffect(() => {
        let callback = (e) => {
            setColor(e.target.selected[0]);
        }
        colorSelector.current?.addEventListener('change', callback)
        colorSelector.current.selected = color ?? 'auto';
        return () => {
            colorSelector.current?.removeEventListener('change', callback);
        }
    }, [])

    React.useEffect(() => {
        let callback = (e) => {
            setScale(e.target.selected[0]);
        }
        scaleSelector.current?.addEventListener('change', callback)
        scaleSelector.current.selected = scale ?? 'medium';
        return () => {
            scaleSelector.current?.removeEventListener('change', callback);
        }
    }, [])

    React.useEffect(() => {
        if (!color || color === 'auto') {
            localStorage.removeItem(T_LAYOUT_COLOR_KEY);
        } else {
            localStorage.setItem(T_LAYOUT_COLOR_KEY, color);
        }

        if (!scale || scale === 'medium') {
            localStorage.removeItem(T_LAYOUT_SCALE_KEY);
        } else {
            localStorage.setItem(T_LAYOUT_SCALE_KEY, scale);
        }
        window.dispatchEvent(new Event('storage'));
    }, [color, scale])

    return (
        <p>
            <sp-table no-hover>
                <sp-table-body>
                    <sp-table-row>
                        <sp-table-cell>
                            Scale
                            <sp-help-text>Controls the dimensions of the controls and text on the screen</sp-help-text>
                        </sp-table-cell>
                        <sp-table-cell></sp-table-cell>
                        <sp-table-cell>
                            <sp-action-group compact selects='single' ref={scaleSelector}>
                                <sp-action-button value='medium'>
                                    Medium
                                </sp-action-button>
                                <sp-action-button value='large'>
                                    Large
                                </sp-action-button>
                            </sp-action-group>
                        </sp-table-cell>
                    </sp-table-row>
                    <sp-table-row>
                        <sp-table-cell>
                            Mode
                            <sp-help-text>Change the warmth of the colours used</sp-help-text>
                        </sp-table-cell>
                        <sp-table-cell></sp-table-cell>
                        <sp-table-cell><sp-action-group compact selects='single' ref={colorSelector}>
                            <sp-action-button value='auto'>
                                Auto
                            </sp-action-button>
                            <sp-action-button value='light'>
                                Light
                            </sp-action-button>
                            <sp-action-button value='dark'>
                                Dark
                            </sp-action-button>
                        </sp-action-group></sp-table-cell>
                    </sp-table-row>
                </sp-table-body>
            </sp-table>
        </p>
    )
}
