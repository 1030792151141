import { ExtensionSlot, PageComponentProps } from 'piral-core'
import { Sidebar } from 'react-pro-sidebar';
import { Menu } from 'piral';
import * as React from 'react'
import './ModuleLayout.scss'
import { SearchInput } from 'piral-search';
import SearchMenu from '../components/SearchMenu';

const sidebarCollapsedKey = 't-ml-sidebar-collapsed';
const sidebarToggledKey = 't-ml-sidebar-toggled';

export default function ModuleLayout(props: PageComponentProps) {

    const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(localStorage.getItem(sidebarCollapsedKey) ?? false)
    const [isSidebarToggled, setIsSidebarToggled] = React.useState(localStorage.getItem(sidebarToggledKey) ?? false)
    const [color, setColor] = React.useState('');

    React.useEffect(() => {
        localStorage.setItem(sidebarCollapsedKey, isSidebarCollapsed.toString())
        localStorage.setItem(sidebarToggledKey, isSidebarToggled.toString())
    }, [isSidebarCollapsed, isSidebarToggled])

    React.useEffect(() => {
        let callback = () => {
            var spTheme = document.querySelector('sp-theme');
            setColor(spTheme.getAttribute('color'));
        };
        callback();
        var obs = new MutationObserver(callback);
        var el = document.querySelector('sp-theme');
        if (!el) return;
        obs.observe(el, { attributes: true, attributeFilter: ['color'] });
        return () => {
            obs.disconnect();
        };
    }, [])

    return (
        <div className='module-layout'>
            <ExtensionSlot name='module-extension'></ExtensionSlot>
            <sp-top-nav quiet>
                <sp-action-button quiet onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
                    <sp-icon-show-menu slot='icon'></sp-icon-show-menu>
                </sp-action-button>
                <sp-action-button quiet disabled>
                    {/* <sp-icon slot='icon'>
                        <t-logo></t-logo>
                    </sp-icon> */}
                    <div className='logo'>
                        <t-logo dark={color === 'dark' ? true : undefined}></t-logo>
                    </div>
                </sp-action-button>
                <div className='module-header-slot'>
                    <ExtensionSlot name='module-header'></ExtensionSlot>
                </div>
                <div flex-layout='' flex-gap=''>
                    <Menu type='header'></Menu>
                </div>
            </sp-top-nav>
            <div className='main-container'>
                <Sidebar collapsed={isSidebarCollapsed} toggled={isSidebarToggled} backgroundColor='transparent' collapsedWidth='0px'>
                    <Menu type='general'></Menu>
                </Sidebar>
                <main>{props.children}</main>
            </div>
        </div>
    )
}
