import { SearchInputProps } from 'piral-search'
import * as React from 'react'
import './SearchInput.scss'
import { useGlobalState } from 'piral-core'

const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback(...args);
        }, wait);
    };
}

export default function SearchInput(props: SearchInputProps) {
    const data = useGlobalState(m => m.data);

    if (data.appsettings?.value.preview !== true) {
        return null;
    }

    var onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    var onInput = debounce((e: any) => props.setValue(e.target.value), 280);

    return (
        <div className='search-input'>
            <sp-search value={props.value} onSubmit={onSubmit} onInput={onInput}></sp-search>
        </div>
    )
}
