import * as React from 'react'
import './LoadingIndicator.scss'

export default function LoadingIndicator() {
    return (
        <div className='loadingindicator-container'>
            <sp-progress-circle indeterminate></sp-progress-circle>
        </div>
    )
}
