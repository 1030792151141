import { PageComponentProps } from 'piral-core'
import * as React from 'react'

export default function PageLayout(props: PageComponentProps) {
    return (
        <>
            {props.children}
        </>
    )
}
